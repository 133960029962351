import {SignIn} from "./scenes/auth/SignIn";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {themeSettings} from "./theme";
import {useSelector} from "react-redux";
import {BrowserRouter, Navigate, Routes, Route, useLocation} from "react-router-dom";
import {useMemo} from "react";

function App() {

    const mode = useSelector((state) => state.global.mode);
    const theme = useMemo(()=>createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
        <BrowserRouter >
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Routes>
                    <Route path="/" element={<SignIn/>} />
                </Routes>
            </ThemeProvider>
        </BrowserRouter>
    </div>
  );
}

export default App;
