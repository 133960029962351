import {Avatar, Box, Button, Container, TextField, Typography} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import * as React from "react";

export const SignIn = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState(false);
    const [loginErrorText, setLoginErrorText] = useState("");
    const [pwdError, setPwdError] = useState(false);
    const [pwdErrorText, setPwdErrorText] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const login = data.get('login');
        const pwd = data.get('pwd');

        if (login.trim() === "")
        {
            setLoginError(true);
            setLoginErrorText("Введите логин")
            return;
        }

        if (pwd.trim() === "")
        {
            setPwdError(true);
            setPwdErrorText("Введите пароль")
            return;
        }

        const loggedInResponse = await fetch( `url`, {
            method: "POST",
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${pwd}`
            },
            body: JSON.stringify({})
        });

        if (loggedInResponse.status !== 200){
            setPwdError(true)
            setPwdErrorText('Ошибка входа')
            return;
        }

        setPwdError(false);
        setLoginError(false);

        dispatch(
        );
        navigate("/dashboard");
    };

    return (
        <Container>
            <Box sx={{
                mt: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                ml: 25,
                mr: 25
            }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Введите учетные данные
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, ml:30, mr: 30 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="login"
                        label="Логин"
                        name="login"
                        autoFocus
                        error={loginError}
                        helperText={
                            loginError ? loginErrorText : ""
                        }
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="pwd"
                        label="Пароль"
                        name="pwd"
                        error={pwdError}
                        helperText={
                            pwdError ? pwdErrorText : ""
                        }
                        type="password"
                    />
                    <Button
                        color="primary"
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Вход
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}