import {createApi, fetchBaseQuery, retry} from "@reduxjs/toolkit/query/react";

const baseUrl = `url`;

export const clientApi = createApi({
    reducerPath: "clientApi",
    endpoints: (build) => ({
    })
})

export const {

} = clientApi